.wp-block {
    max-width: 80% !important;
}


.acf-button-group {
  width:100%;

  label {
    text-align: left !important;
  }
}
