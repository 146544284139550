
a, button, input, ul li, li, p, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6  {

}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6  {


font-family: "Yrsa";


}



/* Round Call out HP-blocks */



// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {


  .call-out-round {
    right:12px !important;
    bottom:-38px;

    .call-out-text {
        height:125px;

    }
  }
 }



// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {



 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 576px) {
  .call-out-round {
    right:-24px;
    bottom:-38px;

    .call-out-text {
        height:125px;

    }
  }




 }


 // Image hover

 .card-img-hover {
   display: block;

   > * {
     float: left;
   }
 }

 .card-img-hover .card-img-top {
   transition: $transition-fade;
 }

 .card-img-hover .card-img-back {
   opacity: 0;
 }

 .card-img-hover .card-img-front {
   margin-left: -100%;
 }

 .card-img:hover .card-img-hover .card-img-back {
   opacity: 1;
 }

 .card-img:hover .card-img-hover .card-img-front {
   opacity: 0;
 }


 .dv-slick__arrow-classic:hover {
     color: #c80b0e !important;
     background-color: #fff;
 }


.hp-product-blocks .btn-outline-dark:hover {
  color:#fff !important;
}
