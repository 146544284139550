.t-dark {
  color: $primary-darker !important;

}
.btn-primary {
  color: $white;
  background: $orange !important;
  background-color: $orange !important;
  border:none;

  &:hover,
  &:focus {
    background: $orange-darker !important;
    background-color: $orange-darker !important;
    border:none;
    &::after {
      opacity: 1;
    }
  }

}


.featured-subjects .card:focus,
.featured-subjects .card:hover,

.uitspraken-cat .card:focus,
.uitspraken-cat .card:hover,
.hp-blocks .list-group-item:focus,
.hp-blocks .list-group-item:hover,
.related-content .list-group-item:focus,
.related-content .list-group-item:hover {
    color: #fff;
    text-decoration: none;
    background:#004683!important;
    background-color: #004683!important;

    a, a:hover {
        color: #fff !important;
    }

    span {
        color: #fff !important;
    }
}


.hp-btn-outline-dark {

          display: inline-block;

          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          border: 2px solid #131313;
          padding: .55rem 1rem;

          line-height: 1.5;
          border-radius: 6px;
          transition: all .2s ease-in-out;
          border-radius: 6.1875rem;
          // background-color: #131313;
          // border-color: #131313;

          text-transform: lowercase!important;
          font-size: 1.2rem!important;

          color:#ffffff !important;
          font-weight: 400!important;
          background-color: #131313;
              border-color: #131313;


  &:hover,
  &:focus {


border:1px solid #131313 !important;
color: #131313 !important;
          font-weight: 400!important;
          background-color: #f8f9fa;
              border-color: #f8f9fa;

    &::after {
         opacity: 1;
    }

  }

}
