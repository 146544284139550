.featured-pages-top {
  background: rgba(220,221,222,0.10);
  border-bottom: 1px solid #F6F9FB;
}


// html {
//   position: relative;
//   min-height: 100%;
// }
//
// body {
//   /* Margin bottom by footer height */
//   height: 100%;
//   margin-bottom: 72px;
// }

// .dv-sticky-footer {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   /* Set the fixed height of the footer here */
//   height: 72px;
// }
