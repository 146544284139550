/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/

.dv-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dv-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.dv-fancybox-theme .fancybox-bg {
  background-color: $dark;
}

.fancybox-controls--canzoomIn .fancybox-placeholder,
.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}

.dv-blur-30 {
  filter: blur(30px);
}

@charset "UTF-8";
.fancybox-enabled {
  overflow: hidden; }

.fancybox-enabled body {
  overflow: visible;
  height: 100%; }

.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden; }

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption-wrap {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility 0s linear .25s;
  box-sizing: border-box; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s, visibility 0s; }

.fancybox-infobar {
  top: 0;
  left: 50%;
  margin-left: -79px; }

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased; }

.fancybox-toolbar {
  top: 0;
  right: 0; }

.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translate3d(0, 0, 0);
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: visible; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
  background: #000; }

.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
  background: #E5E3DF; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0 44px;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box; }

.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff; }

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff; }

.fancybox-error {
  margin: 0;
  padding: 40px;
  width: 100%;
  max-width: 380px;
  background: #fff;
  cursor: default; }

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px "Helvetica Neue",Helvetica,Arial,sans-serif; }

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer; }

.fancybox-close-small:after {
  content: '×';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background .25s;
  box-sizing: border-box;
  z-index: 2; }

.fancybox-close-small:focus:after {
  outline: 1px dotted #888; }

.fancybox-close-small:hover:after {
  color: #555;
  background: #eee; }

.fancybox-slide--iframe .fancybox-close-small {
  top: 0;
  right: -44px; }

.fancybox-slide--iframe .fancybox-close-small:after {
  background: transparent;
  font-size: 35px;
  color: #aaa; }

.fancybox-slide--iframe .fancybox-close-small:hover:after {
  color: #fff; }

/* Caption */
.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0 30px;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%);
  pointer-events: none; }

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none; }

.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
  pointer-events: all; }

.fancybox-caption a {
  color: #fff;
  text-decoration: underline; }

/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: transparent;
  color: #ddd;
  border-radius: 0;
  cursor: pointer;
  vertical-align: top;
  outline: none; }

.fancybox-button[disabled] {
  cursor: default;
  pointer-events: none; }

.fancybox-infobar__body, .fancybox-button {
  background: rgba(30, 30, 30, 0.6); }

.fancybox-button:hover:not([disabled]) {
  color: #fff;
  background: rgba(0, 0, 0, 0.8); }

.fancybox-button::before,
.fancybox-button::after {
  content: '';
  pointer-events: none;
  position: absolute;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.9;
  box-sizing: border-box;
  display: inline-block; }

.fancybox-button[disabled]::before,
.fancybox-button[disabled]::after {
  opacity: 0.3; }

.fancybox-button--left::after,
.fancybox-button--right::after {
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor; }

.fancybox-button--left::after {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg); }

.fancybox-button--right::after {
  right: 20px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.fancybox-button--left {
  border-bottom-left-radius: 5px; }

.fancybox-button--right {
  border-bottom-right-radius: 5px; }

.fancybox-button--close::before, .fancybox-button--close::after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px); }

.fancybox-button--close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.fancybox-button--close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

/* Navigation arrows */
.fancybox-arrow {
  position: absolute;
  top: 50%;
  margin: -50px 0 0 0;
  height: 100px;
  width: 54px;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 99995;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity .25s; }

.fancybox-arrow::after {
  content: '';
  position: absolute;
  top: 28px;
  width: 44px;
  height: 44px;
  background-color: rgba(30, 30, 30, 0.8);
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px 24px; }

.fancybox-arrow--right {
  right: 0; }

.fancybox-arrow--left {
  left: 0;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1); }

.fancybox-arrow--right::after,
.fancybox-arrow--left::after {
  left: 0; }

.fancybox-show-nav .fancybox-arrow {
  opacity: 0.6; }

.fancybox-show-nav .fancybox-arrow[disabled] {
  opacity: 0.3; }

/* Loading indicator */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0; }

.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0; }

.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0; }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0; }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
  opacity: 0; }

.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: 0; }

.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1; }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0; }

.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0; }

.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-infobar {
    left: 0;
    margin-left: 0; }
  .fancybox-button--left,
  .fancybox-button--right {
    display: none !important; }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0; } }

/* Fullscreen  */
.fancybox-button--fullscreen::before {
  width: 15px;
  height: 11px;
  left: calc(50% - 7px);
  top: calc(50% - 6px);
  border: 2px solid;
  background: none; }

/* Slideshow button */
.fancybox-button--play::before,
.fancybox-button--pause::before {
  top: calc(50% - 6px);
  left: calc(50% - 4px);
  background: transparent; }

.fancybox-button--play::before {
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px; }

.fancybox-button--pause::before {
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px 0 2px; }

/* Thumbs */
.fancybox-thumbs {
  display: none; }

.fancybox-button--thumbs {
  display: none; }

@media all and (min-width: 800px) {
  .fancybox-button--thumbs {
    display: inline-block; }
  .fancybox-button--thumbs span {
    font-size: 23px; }
  .fancybox-button--thumbs::before {
    width: 3px;
    height: 3px;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, 0 0 0 32px inset, -4px 0 0, 4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0; }
  .fancybox-thumbs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 220px;
    margin: 0;
    padding: 5px 5px 0 0;
    background: #fff;
    word-break: normal;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    z-index: 99995; }
  .fancybox-show-thumbs .fancybox-thumbs {
    display: block; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 220px; }
  .fancybox-thumbs > ul {
    list-style: none;
    position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0; }
  .fancybox-thumbs > ul > li {
    float: left;
    overflow: hidden;
    max-width: 50%;
    padding: 0;
    margin: 0;
    width: 105px;
    height: 75px;
    position: relative;
    cursor: pointer;
    outline: none;
    border: 5px solid transparent;
    border-top-width: 0;
    border-right-width: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-sizing: border-box; }
  li.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, 0.1); }
  .fancybox-thumbs > ul > li > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    max-height: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .fancybox-thumbs > ul > li:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    border: 4px solid #4ea7f9;
    z-index: 99991;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
    opacity: 1; } }
