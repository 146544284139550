h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
   font-family: "Yrsa", Helvetica, Arial, sans-serif !important;
   font-weight: 500!important;
}



.text-red {
  color:#C80B0E !important;
}

span.square {
  background-image:
         url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'><circle cx='3' cy='3' r='3' fill='#B6050C' fill-rule='evenodd'/></svg>");
}

// ul {
//   list-style: none; /* Remove default bullets */
// }
ul li {
      line-height: 2;
}

ul li  {
  content: "";
}

.green-dots li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: $primary !important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: .8em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

// body {
//   color:#77838f !important;
// }

.bg-green {
 background:  $primary-darker;
 background-color:  $primary-darker;
}


.line-height-title {
  line-height: 1.2em !important;
}


.support-block {
  .btn {

  @extend .btn-block;
}
}

.seeh-block-shadow {
  background: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(131,131,131,0.20);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
