.t-dark {
  color: $primary-darker !important;

}
.t-light {
  color: $primary-lighter !important;
}

.text-orange {
  color: $orange !important;

}

.text-default {
color:#1e2022;

}

.text-primary {
  color:$primary !important;
}


.alignright {
  float:right;
  margin:20px;
  margin-right:0px;
}
