
  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap
footer {
  background: #000;
  background-color: #000;
}
footer h4 {
  color:#fff !important;
}
  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
  footer {
    background: #000;
    background-color: #000;
  }

   }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {


   }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {

  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {




   }
