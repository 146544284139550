
.hero-message-block-inside {
background: #004683;
color:#fff;
}

.bg-header-shape {
  width: 1200px;
  height:295px;
  -webkit-clip-path: polygon(0 0, 100% 1%, 99% 100%, 3% 100%);
  clip-path: polygon(0 0, 100% 1%, 99% 100%, 3% 100%);
}

.bg-header-shape-small {
  width: 1200px;
  height:200px;
  -webkit-clip-path: polygon(0 0, 100% 1%, 99% 100%, 3% 100%);
  clip-path: polygon(0 0, 100% 1%, 99% 100%, 3% 100%);
}

  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    .bg-header-shape-small,
    .bg-header-shape {
      width: 100%;
      height:220px;
      -webkit-clip-path: none;
      clip-path: none;
    }

    .bg-header-shape-small {
        height:160px;
    }

    .hero-message-block {
      margin-top:-22px;
    }
   }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

    .hero-message-block {
      margin-top:-44px;
    }
   }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .hero-message-block {
      margin-top:-88px;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {


    .hero-message-block {
      margin-top:-88px;
    }

   }
