.navbar-top-bg {
  background: #F9FBFD;
  background-color: #F9FBFD;
}
.navbar-shadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.20);

}

.menu-item {
  @extend .pl-2;



}


.nav-link {
  color: $dark;

  font-size: 1.3em;
  font-weight: 700 !important;

  &:hover,
  &:focus {
  color:$primary !important;


  }

  .active {
    color:$primary !important;

  }
}

.active {
  color:$primary !important;

}
#menu-top-nav {

  .menu-item {

.telnr,
  a {
    @extend .text-muted;
    font-size: .9em;

    &:hover,
    &:focus {
    color:$primary !important;


    }
}
  }
}
